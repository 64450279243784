import React from "react";
import Layout from "../../components/layout";
import PaymentInfo from "../../components/patient-information/payment-info";
import SEO from "../../components/seo";

const PaymentInfoPage = () => (
  <Layout>
    <SEO title="Payment Information" />
    <PaymentInfo />
  </Layout>
);

export default PaymentInfoPage;
