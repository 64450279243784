import React from "react";
import {
  Container,
  Title,
  Text,
  ListBox,
  ListText,
  StyledLink,
  StyledAnchor,
  StyledCardImg,
  CreditCardsContainer,
} from "./styles";
import Image from "./Images";

import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";

const PaymentInfo = () => {
  const image = Image();
  return (
    <>
      <Breadcrumb pageTitle="Payment Information">
        <BreadcrumbItem
          Link="/patient-information/payment-information"
          Text="Payment Information"
        />
      </Breadcrumb>

      <PageContainer>
        <Container>
          <Title>Payment Information</Title>
          <Text>
            Providing a high standard of orthodontic care also means making your
            orthodontic treatment affordable. The costs associated with
            orthodontics varies per treatment, therefore, every patient can
            expect slightly different prices depending on the individualized
            plan worked out together at the initial consultation.
          </Text>
          <Text>
            Orthodontic treatment is not covered by Medicare or the Child Dental
            Benefit Scheme, however, private health insurance can often cover a
            portion of the cost depending upon the requirements set out by each
            health fund.
          </Text>
          <Text>
            If you do have private health insurance, we will provide you with
            all the required information to submit a claim.{" "}
            <strong>
              <i>
                We also offer the ability to claim private health insurance
                rebates through our HICAPS terminal at Gippsland Orthodontics.
              </i>
            </strong>{" "}
            Our orthodontist and staff will discuss with you the cost of your
            treatment, and each of your available payment plan options, so that
            you are able to make the best choice for you, and your smile. We
            will work with you to create a payment plan that fits your budget,
            and you will know what to expect before beginning treatment.
          </Text>
          <Title>We offer flexible payment plans that are interest free.</Title>
          <Text>
            At Gippsland Orthodontics we have created a number of extended
            payment options, as we know there are often many demands on a
            family&#39;s budget.
          </Text>
          <Title>What payment plans are available?</Title>
          <Text>
            Information regarding payment plans will be discussed during your
            initial consultation.
          </Text>
          <Text>
            The payment plan consists of an initial deposit at the beginning of
            your treatment, followed by automated instalments. The price of the
            deposit and number of instalments will depend on several things,
            including the:
          </Text>
          <ListBox>
            <ListText>1. Type of treatment being completed</ListText>
          </ListBox>
          <ListBox>
            <ListText>2. Expected duration of treatment</ListText>
          </ListBox>
          <ListBox>
            <ListText>
              3. Deposit and the number of instalments can sometimes be tailored
              to your needs and budget as well
            </ListText>
          </ListBox>
          <br />
          <Text>
            Further, the instalments can be organised for a fortnightly or
            monthly basis and can be direct debited, or direct deposited from
            your bank account, or credit card.
          </Text>
          <CreditCardsContainer>
            <Text>
              We accept <i>Visa</i>, <i>Mastercard</i>, and{" "}
              <i>American Express</i> cards.
            </Text>
            <StyledCardImg fluid={image.creditCards} />
          </CreditCardsContainer>

          <Title>Can I make a full payment at the start of treatment?</Title>
          <Text>
            If you prefer, we can accept payment in full at the start of your
            treatment with a discount applied for full payment.
          </Text>
          <Title>Do you offer discounts?</Title>
          <Text>
            At Gippsland Orthodontics, we have a number of discounts available.
            A discount is available for an initial full payment at the start of
            treatment. We also offer family discounts to siblings, parents and
            children of existing patients.
          </Text>
          <Text>
            Please ask us for details about any discounts that may be applicable
            to your treatment!
          </Text>
          <Text>
            If you have any questions regarding costs, payments, payment plans,
            and treatment options please do hesitate to contact the team at
            Gippsland Orthodontics.
          </Text>
          <Text>
            If you would like to book an appointment, please{" "}
            <StyledLink to="/contact">click here</StyledLink>.
          </Text>
          <Title>Currently in treatment?</Title>
          <Text>
            If you are a patient undergoing treatment and would like to make a
            payment through BPOINT please{" "}
            <StyledAnchor
              href="https://www.bpoint.com.au/pay/5353109392736093"
              target="_blank"
            >
              click here
            </StyledAnchor>
            .
          </Text>
        </Container>
      </PageContainer>
    </>
  );
};

export default PaymentInfo;

// export const PaymentInfo = () => {
//   return (
//     <PageWrapperInfo>
//       <ErrTitle>Payment Information</ErrTitle>
//       <ErrMsg>Please make a payment with your reference number.</ErrMsg>
//       <ButtonWrapper>
//         <ExternalButton
//           href="https://www.bpoint.com.au/pay/5353109392736093"
//           title="Paymenet"
//         >
//           Go to Payment
//         </ExternalButton>
//       </ButtonWrapper>
//     </PageWrapperInfo>
//   );
// };
